.active {
    background-color: #CACACA !important;
    display: block;
    transition: all 0.3s;
}

.css-10ov4fw-MuiPaper-root-MuiAppBar-root {
    background-color: #fff !important;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    background-color: #fff !important;
    box-shadow: unset !important;
}

.css-gvs1ut-MuiPaper-root-MuiAppBar-root {
    background-color: #fff !important;
    box-shadow: unset !important;
}

.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
    justify-content: space-around;
}

body {
    background-color: #F4F4F4 !important;
}

.main-text {
    color: #155177;
}

.main-bg {
    background-color: #155177;
}

.css-n8kuc8 {
    padding: 0px !important;
}

.scan,
.manual {
    height: 2.5rem;
}

.manual {
    background-color: transparent;
    border: 1px solid #155177;
    transition: all 0.2s;
}

.manual:hover {
    background-color: #155177;
    border: 1px solid #155177;
    color: #fff;
}

.vh-90 {
    min-height: 91vh;
}

input {
    outline: 0;
    border: 1px solid #ddd;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-dialog {
    max-width: 650px !important;
}

.user-img {
    width: 80px;
    height: 80px;
}


/* HTML: <div class="loader"></div> */
/* background: conic-gradient(#0000 50%,#f03355) content-box; */
/* .loader {
    width: 60px;
    --b: 10px; 
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    border: 30px #abb2b9 dashed;
    border-top-color:#4b5a6a;
    
    -webkit-mask:
      repeating-conic-gradient(#0000 0deg,#000 1deg 40deg,#0000 0deg 45deg),
      radial-gradient(farthest-side,#00000000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
            mask-composite: intersect;
    animation:l4 1s infinite steps(10);
  } */
/* @keyframes l4 {to{transform: rotate(1turn)}} */


.css-1ygcj2i-MuiTableCell-root {
    font-family: sans-serif !important;
    color: #155177 !important;
    font-weight: 700 !important;
}

.css-1ex1afd-MuiTableCell-root {
    text-align: center !important;
    font-weight: 600 !important;
}

p {
    margin-bottom: 0 !important;
}

.h-400 {
    height: 500px !important;
}

.page-item:first-child .page-link {
    border-radius: 8px !important;
    border: 1px solid black !important;
    color: black !important;
}

.page-item:last-child .page-link {
    border-radius: 8px !important;
    border: 1px solid black !important;
    color: black !important;
}
.page-link{
    color: black !important;
    border: none !important;
    border-radius: 10px !important;
}
.active>.page-link, .page-link.active{
    color: #007BFF !important;
    background-color: #F9F9F9 !important;
    border: none !important;
}
.pagination .active{
    background-color: unset !important;
}
thead tr th{
    color: #155177 !important;
}
thead{
    border-bottom: 2px solid black !important;
}
td{
    width: 1000px;
}
.table{
    width: 1100px !important;
}
.events-none{
    pointer-events: none;
}